export const formatPrice = (price) => {
  const formattedPrice = Intl.NumberFormat('ru-RU', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(price);

  const hasFraction = price % 1 !== 0;

  return hasFraction
    ? Intl.NumberFormat('ru-RU', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(price)
    : formattedPrice;
};
