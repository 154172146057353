import {Bar, BarChart, XAxis, YAxis, Tooltip, ResponsiveContainer, Rectangle} from "recharts";
import React, {useEffect, useState} from "react";
import { DatePicker, Select } from "antd";
import useCities, {useFilias} from "../../../api/common/common-hooks";
import { useOrderStatus } from "../../../api/statistics/statistics";
import dayjs from "dayjs";
import Cookies from "js-cookie";
import {jwtDecode} from "jwt-decode";
import {formatPrice} from "../../../hooks/format-price";

const { RangePicker } = DatePicker;
export const SellingStatistics = ({language, translation}) => {

  const filteredMonth = [
    { number: '01', ru: 'Январь', kz: 'Қаңтар', ch: '一月' },
    { number: '02', ru: 'Февраль', kz: 'Ақпан', ch: '二月' },
    { number: '03', ru: 'Март', kz: 'Наурыз', ch: '三月' },
    { number: '04', ru: 'Апрель', kz: 'Сәуір', ch: '四月' },
    { number: '05', ru: 'Май', kz: 'Мамыр', ch: '五月' },
    { number: '06', ru: 'Июнь', kz: 'Маусым', ch: '六月' },
    { number: '07', ru: 'Июль', kz: 'Шілде', ch: '七月' },
    { number: '08', ru: 'Август', kz: 'Тамыз', ch: '八月' },
    { number: '09', ru: 'Сентябрь', kz: 'Қыркүйек', ch: '九月' },
    { number: '10', ru: 'Октябрь', kz: 'Қазан', ch: '十月' },
    { number: '11', ru: 'Ноябрь', kz: 'Қараша', ch: '十一月' },
    { number: '12', ru: 'Декабрь', kz: 'Желтоқсан', ch: '十二月' }
  ];

  const languageMonths = [
    { kz: 'қаңтардағы', ru: 'января', ch: '一月' },
    { kz: 'ақпандағы', ru: 'февраля', ch: '二月' },
    { kz: 'наурыздағы', ru: 'марта', ch: '三月' },
    { kz: 'сәуірдегі', ru: 'апреля', ch: '四月' },
    { kz: 'мамырдағы', ru: 'мая', ch: '五月' },
    { kz: 'маусымдағы', ru: 'июня', ch: '六月' },
    { kz: 'шілдедегі', ru: 'июля', ch: '七月' },
    { kz: 'тамыздағы', ru: 'августа', ch: '八月' },
    { kz: 'қыркүйектегі', ru: 'сентября', ch: '九月' },
    { kz: 'қазандағы', ru: 'октября', ch: '十月' },
    { kz: 'қарашадағы', ru: 'ноября', ch: '十一月' },
    { kz: 'желтоқсандағы', ru: 'декабря', ch: '十二月' },
  ];

  const today = dayjs();
    const lastMonth = today.subtract(1, "month").add(1, "day");

    const [country, setCountry] = useState('China');
    const [dateSorted, setDateSorted] = useState([lastMonth, today]);
    const [filialId, setFilialId] = useState(null);
    const [cityId, setCityId] = useState(null);
    const [filteredStatistics, setFilteredStatistics] = useState();
    const [role, setRole] = useState(null);
    const [totalSumByParams, setTotalSumByParams] = useState(0);
    const [totalQtyByParams, setTotalQtyByParams] = useState(0);
    const [selectedParams, setSelectedParams] = useState(null);
    const [currentMonth, setCurrentMonth] = useState('');
    const [graphFormat, setGraphFormat] = useState(null);

    const { data: filial } = useFilias(cityId);
    const {data: statistics} = useOrderStatus({startDate: dateSorted[0].format('YYYY-MM-DDT00:00:00'), endDate: dateSorted[1].format('YYYY-MM-DDT23:59:59'), country: country, filialId: filialId, cityId: cityId});
    const {data: cities} = useCities();

    useEffect(() => {
    }, [selectedParams])

    useEffect(() => {
      const token = Cookies.get('token');
      if (token && typeof token === 'string') {
        try {
          const decodedToken = jwtDecode(token);
          setRole(decodedToken.role);
        } catch (error) {
          setRole('');
        }
      } else {
        setRole('');
      }
    }, []);

    useEffect(() => {
        const diff = Math.abs( new Date(dateSorted[0] - new Date(dateSorted[1]))) / (1000 * 60 * 60 * 24);
        if(statistics) {
          if(diff < 1) {
            setFilteredStatistics(statistics.map(item => ({
              ...item,
              period: `${item.period.slice(11)}:00`,
            })))
            setGraphFormat('day');
          } else if (diff >= 1 && diff <= 31) {
            setFilteredStatistics(statistics.map(item => ({
              ...item,
              period: item.period.slice(8, 10),
              month: item.period.slice(5, 7),
            })))
            setGraphFormat('month');
          } else {
            setFilteredStatistics(statistics.map(item => ({
              ...item,
              period: filteredMonth.find((month) => month.number === item.period.slice(5, 7))?.[language]
            })));
            setGraphFormat('months');
          }
        }
        if(statistics) {
          setTotalSumByParams(Math.round(Number(statistics.reduce((sum, item) => sum + item.totalAmount, 0))));
          setTotalQtyByParams(Number(statistics.reduce((sum, item) => sum + item.totalCount, 0)));
        }
      }, [statistics, language]);

    const onChange = (dates) => {
      setDateSorted(dates);
      setSelectedParams(null);
      setGraphFormat('nothing');
      setCityId(null);
      setFilialId(null);
    };

    const onChangeSelect = (country) => {
      if(country === "ch"){
        setCountry('China');
      } else {
        setCountry('Turkey');
      }
    }

    const onChangeCity = (cityId) => {
      setCityId(cityId);
      setFilialId(null);
    }

    const onChangeFilial = (filialId) => {
        setFilialId(filialId);
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <div
                style={{
                  backgroundColor: 'white',
                  padding: '20px',
                  borderRadius: '24px',
                  minWidth: 'calc(100% - 343px) !important',
                  height: 'fit-content',
                  margin: '22px 171.5px',
              }}
            >
              <div style={{
                display: 'flex',
                gap: '16px',
                marginBottom: '43px',
              }}
              >
                <RangePicker
                  allowClear={false}
                  defaultValue={[lastMonth, today]}
                  format={{
                    format: 'YYYY-MM-DD',
                    type: 'mask',
                  }}
                  onChange={onChange}
                />
                <Select
                  onChange={onChangeSelect}
                  defaultValue={'ch'}
                  style={{width: 121, height: 46, borderRadius: 8, backgroundColor: '#b1b3b6'}}
                  options={[
                    {value: 'ch', label: translation('china')},
                    {value: 'tr', label: translation('turkey')},
                  ]}
                />
                {
                  role && role === 'RoleLeadAdmin' ? (
                    <>
                      <Select
                        value={cityId}
                        onChange={onChangeCity}
                        placeholder={translation('chooseCity')}
                        options={cities ? cities.map((city) => (
                          {value: city.id, label: city.name}
                        )) : []}
                        style={{minWidth: 121, height: 46, borderRadius: 8}}
                      />
                      <Select
                        value={filialId}
                        onChange={onChangeFilial}
                        placeholder={translation('chooseFilial')}
                        options={filial ? filial.content.map((filial) => (
                          {value: filial.id, label: filial.name}
                        )) : []}
                        style={{minWidth: 121, height: 46, borderRadius: 8, display: cityId ? 'block' : 'none'}}
                      />
                    </>
                  ) : ''
                }
                {/*<Select*/}
                {/*  placeholder={'Выберите город'}*/}
                {/*  options={filial ? filial.map((city) => (*/}
                {/*    {value: city.index, label: city.name}*/}
                {/*  )) : []}*/}
                {/*  style={{width: 121, height: 46, borderRadius: 8}}*/}
                {/*/>*/}
              </div>
              <div
                style={{
                  width: '100%',
                  // display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '16px',
                  fontFamily: 'Roboto',
                  fontSize: '18px',
                  fontWeight: 500,
                  lineHeight: '24px',
                  color: '#100E71',
                }}
              >
                <div>
                  {
                    language === 'ru' ?
                      (
                        <>
                          {translation('ordersAmount')}
                          {selectedParams && ` ${graphFormat === 'day' ? 'в' : 'за'} ${graphFormat === 'months' ? selectedParams : selectedParams}`}
                          {graphFormat === 'month' && selectedParams ? ` ${languageMonths[Number(currentMonth) - 1].ru}` : ''}: {formatPrice(totalQtyByParams)} {translation('amountAfterNumber')}
                        </>
                      ) : language === 'kz' ? (
                        <>
                          {graphFormat === 'months' ? selectedParams : selectedParams} {graphFormat === 'month' && selectedParams ? languageMonths[Number(currentMonth - 1)].kz : ''} {selectedParams ? translation('ordersAmount').toLowerCase() : translation('ordersAmount')}
                          : {formatPrice(totalQtyByParams)} {translation('amountAfterNumber')}
                        </>
                      ) : (
                        <>
                          {graphFormat === 'months' ? selectedParams : selectedParams} {graphFormat === 'month' && selectedParams ? languageMonths[Number(currentMonth - 1)].ch : ''} {selectedParams ? translation('ordersAmount').toLowerCase() : translation('ordersAmount')}
                          : {formatPrice(totalQtyByParams)} {translation('amountAfterNumber')}
                        </>
                      )
                  }
                </div>
                <div>
                  {typeof totalSumByParams === 'string'
                    ? totalSumByParams
                    : (
                      <>
                        {
                          language === 'ru' ?
                            (
                              <>
                                {translation('ordersSum')}
                                {selectedParams && ` ${graphFormat === 'day' ? 'в' : 'за'} ${graphFormat === 'months' ? selectedParams : selectedParams}`}
                                {graphFormat === 'month' && selectedParams ? ` ${languageMonths[Number(currentMonth) - 1].ru}` : ''}: {formatPrice(totalSumByParams)} {translation('tenge')}
                              </>
                            ) : language === 'kz' ? (
                              <>
                                {graphFormat === 'months' ? selectedParams : selectedParams} {graphFormat === 'month' && selectedParams ? languageMonths[Number(currentMonth - 1)].kz : ''} {selectedParams ? translation('ordersSum').toLowerCase() : translation('ordersAmount')}
                                : {formatPrice(totalSumByParams)} {translation('tenge')}
                              </>
                            ) : (
                              <>
                                {graphFormat === 'months' ? selectedParams : selectedParams} {graphFormat === 'month' && selectedParams ? languageMonths[Number(currentMonth - 1)].ch : ''} {selectedParams ? translation('ordersSum').toLowerCase() : translation('ordersAmount')}
                                : {formatPrice(totalSumByParams)} {translation('tenge')}
                              </>
                            )
                        }
                      </>
                    )}
                </div>
              </div>
              <ResponsiveContainer width={'100%'} height={400}>
                <BarChart
                  width={48}
                  height={48}
                  data={filteredStatistics ? filteredStatistics : []}
                  clipPath="none"
                >
                  <Tooltip
                    cursor={{fill: 'transparent'}}
                    content={(props) =>
                      props.payload && props.payload.length ? (
                        <div style={{background: 'none'}}>
                          {props.payload.map((item) => (
                            <div
                              key={item.period}
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'start',
                                justifyContent: 'center',
                                minWidth: '100px',
                                height: '50px',
                                color: 'white',
                                backgroundColor: '#100E71',
                                padding: '10px',
                                borderRadius: '12px',
                                fontSize: '10px',
                              }}
                            >
                              <div>{translation('totalSum')}: {formatPrice(item.payload.totalAmount)} {translation('tenge')}</div>
                              <div>{translation('totalAmount')}: {formatPrice(item.payload.totalCount)} {translation('amountAfterNumber')}</div>
                            </div>
                          ))}
                        </div>
                      ) : null
                    }
                  />
                  <XAxis dataKey={"period"} />
                  <YAxis dataKey={'totalCount'}/>
                  <Bar
                    barSize={90}
                    dataKey="totalCount"
                    fill="#b1b3b6"
                    onClick={(e) => {
                      if(!e.payload.totalAmount || e.payload.totalAmount === 0) {
                        setTotalSumByParams(translation('noAmount'));
                      } else {
                        setTotalSumByParams(e.payload.totalAmount);
                      }
                      setSelectedParams(e.payload.period);
                      setTotalQtyByParams(e.payload.totalCount);
                      if(graphFormat === 'month') {
                        setCurrentMonth(e.payload.month);
                      }
                    }}
                    activeBar={<Rectangle fill={'#100E71'}/>
                  }
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
        </div>
    )
}