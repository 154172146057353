import Cookies from "js-cookie";
import {axiosInstances} from "../axios-instances";
import {useQuery} from "react-query";

export const getStatistics = async ({startDate, endDate, country, filialId, cityId}) => {
  const response = await axiosInstances.get('amantrans/statistics/get', {
    params: {
      startDate,
      endDate,
      country,
      filialId,
      cityId
    },
    headers: {
      Authorization: `Bearer ${Cookies.get('token')}`,
    }
  });
  if (response.status !== 200) {
    throw new Error(`Error: ${response.status}`);
  }
  return response.data;
}

export const useOrderStatus = ({startDate, endDate, country, filialId, cityId}) => {
  const { data, refetch, isLoading, error } = useQuery({
    queryKey: ['statistics', startDate, endDate, country, filialId, cityId],
    queryFn: () => getStatistics({startDate, endDate, country, filialId, cityId}),
  });

  return { data, refetch, isLoading, error };
};